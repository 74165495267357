import { flatMap } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  IconButton,
  Modal,
  Notification,
  useModal,
  useTableData,
} from 'react-ui-kit-exante';
import { IOnFetchArguments } from 'react-ui-kit-exante/build/Components/Table/hooks/types';

import { ROUTES } from 'routes';
import { calculateCountOfPages, useRepeatedAction } from 'shared/utils';
import {
  BrandingBuildResponse,
  BrandingBuildPlatform,
} from 'types/brandingBuilds';

import {
  StyledPanel,
  StyledTable,
  StyledSelect,
} from '../../TradingTerminals.styled';
import {
  ALL_BRANDINGS,
  TABLE_IDS,
  TABLE_TITLES,
  EMPTY_DATA,
} from '../../constants';
import {
  useBrandingList,
  useGetBrandingBuildsQuery,
  useTriggerBuildMutation,
  useCancelBuildMutation,
  useGetBrandingBuildStatus,
} from '../../hooks';

import { getColumns } from './columns';

interface BrandingTableParams {
  platform: BrandingBuildPlatform;
}

export const MobileBrandingTable = ({ platform }: BrandingTableParams) => {
  const refSkip = useRef(0);
  const refLimit = useRef(0);
  const refBranding = useRef(ALL_BRANDINGS);
  const refData = useRef(EMPTY_DATA);

  const [branding, setBranding] = useState(
    new URLSearchParams(useLocation().search).get('branding') || ALL_BRANDINGS,
  );

  const modal = useModal();

  const { fetchBrandingBuilds } = useGetBrandingBuildsQuery();
  const { fetchBrandingBuildStatus } = useGetBrandingBuildStatus();

  const brandingOptions = useBrandingList();

  const { triggerBuildMutation } = useTriggerBuildMutation();
  const { cancelBuildMutation } = useCancelBuildMutation();

  const tableId = TABLE_IDS[platform];

  const getBrandingBuilds = useCallback(
    async ({ params: { skip, limit } }: IOnFetchArguments) => {
      // first step: we need to get a list of brandings for which we need to update the status
      if (branding === ALL_BRANDINGS) {
        const responseForUpdateStatus = await fetchBrandingBuilds({
          skip: 0,
          limit: Infinity,
          platform,
        });

        let brandingsForUpdateStatusInfo = flatMap(
          responseForUpdateStatus?.data || [],
          (item) =>
            item.status === 'created' || item.status === 'release-started'
              ? [item.branding]
              : [],
        );

        brandingsForUpdateStatusInfo = Array.from(
          new Set(brandingsForUpdateStatusInfo),
        );

        await Promise.all(
          brandingsForUpdateStatusInfo.map((brandingNameForRequest) =>
            fetchBrandingBuildStatus({
              branding: brandingNameForRequest,
              platform,
            }),
          ),
        );
      } else if (branding !== ALL_BRANDINGS) {
        await fetchBrandingBuildStatus({ branding, platform });
      }

      // second step: getting table data
      const newParams = {
        limit: refLimit.current || limit,
        skip: refSkip.current || skip,
      };

      const response = await fetchBrandingBuilds({
        ...newParams,
        platform,
        ...(branding !== ALL_BRANDINGS ? { branding } : null),
      });

      return response;
    },
    [branding, platform, fetchBrandingBuilds],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getBrandingBuilds },
      tableId,
    }),
    [getBrandingBuilds, tableId],
  );

  const {
    data,
    fetchData,
    isLoading,
    limit,
    page,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    skip,
  } = useTableData(tableDataArgs);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const { startRepeatedAction, cancelRepeatedAction } = useRepeatedAction({
    action: fetchData,
    onNotify: () => {
      Notification.warning({
        title: 'Branding builds will be refreshed in 10 seconds',
      });
    },
  });

  const onBrandingChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === ALL_BRANDINGS) {
        resetFilters(['branding']);
      } else {
        setFilter('branding', event.target.value);
      }

      setBranding(event.target.value);
    },
    [resetFilters, setFilter],
  );

  const addTriggerBuild = useCallback(async () => {
    cancelRepeatedAction();

    const response = await triggerBuildMutation({
      branding,
      platform,
    });

    if (response) {
      fetchData();

      Notification.success({
        title: 'Branding build has been triggered successfully',
      });
    } else {
      Notification.error({
        title: 'An error occurred while triggering the branding build',
      });
    }

    startRepeatedAction();
  }, [
    branding,
    cancelRepeatedAction,
    platform,
    fetchData,
    startRepeatedAction,
    triggerBuildMutation,
  ]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'Trigger Build',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Trigger Build"
            iconSize={24}
            onClick={addTriggerBuild}
            disabled={branding === ALL_BRANDINGS}
          />
        ),
      },
      {
        key: 'Go to All',
        component: (
          <Link to={ROUTES.TRADING_TERMINALS}>
            <IconButton iconName="CloseIcon" iconColor="ghost" iconSize={24} />
          </Link>
        ),
      },
    ],
    [addTriggerBuild, branding],
  );

  const onCancelClickHandler = useCallback(
    ({ branding: rowBranding }: BrandingBuildResponse) => {
      refBranding.current = rowBranding;

      modal.onOpen();
    },
    [modal, platform],
  );

  const cancelBrandingBuild = useCallback(async () => {
    cancelRepeatedAction();
    const response = await cancelBuildMutation({
      branding: refBranding.current,
      platform,
    });

    if (response) {
      fetchData();

      Notification.success({
        title: 'Branding build has been canceled successfully',
      });

      modal.onClose();
    } else {
      Notification.error({
        title: 'An error occurred while canceling the branding build',
      });
    }

    startRepeatedAction();
  }, [
    cancelBuildMutation,
    cancelRepeatedAction,
    modal,
    platform,
    fetchData,
    startRepeatedAction,
  ]);

  const rowActions = useMemo(
    () => ({
      show: true,
      hideEdit: true,
      additionalActions: [
        {
          label: (
            <IconButton
              iconName="CircleCloseIcon"
              iconColor="ghost"
              iconSize={16}
              label="Cancel"
            />
          ),
          onClick: onCancelClickHandler,
          title: 'Cancel',
          show: (_: unknown, values: BrandingBuildResponse) =>
            values.status === 'created',
        },
      ],
    }),
    [modal.onOpen],
  );

  // When a user changes a page or a limit in the table we don't have feedback about it
  useLayoutEffect(() => {
    refSkip.current = skip;
    refLimit.current = limit;
  }, [skip, limit]);

  // We do not always have the opportunity to start a recurring action in callbacks
  // because this function depends on the state
  useEffect(() => {
    cancelRepeatedAction();

    startRepeatedAction();
  }, [branding]);

  useEffect(() => {
    if (data) {
      refData.current = data;
    }
  }, [data]);

  return (
    <StyledPanel disableBodyPaddings>
      <StyledTable
        additionalActions={additionalActions}
        columns={getColumns(platform)}
        data={data?.data || []}
        defaultSortBy={[]}
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        // TODO in UI Kit `values` is just object without Generic
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rowActions={rowActions}
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        tableId={tableId}
        title={
          <>
            <span>{TABLE_TITLES[platform]}</span>
            <StyledSelect
              options={brandingOptions}
              onChange={onBrandingChangeHandler}
              value={branding}
              label="Branding"
            />
          </>
        }
      />

      <Modal
        isOpened={modal.isOpened}
        onClose={modal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: cancelBrandingBuild,
        }}
      >
        <div>Do you want to cancel this build?</div>
      </Modal>
    </StyledPanel>
  );
};
