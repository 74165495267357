import { isArray } from 'lodash';

import { additionalActionWidth, defaultActionColumnWidth } from '../constants';
import type { RowActions } from '../types';

export const calculateActionsColumnWidth = <Data extends object>(
  rowActions: RowActions<Data>,
) => {
  if (!rowActions.show) {
    return 0;
  }

  const additionalActions = isArray(rowActions?.additionalActions)
    ? rowActions?.additionalActions
    : (rowActions?.additionalActions && rowActions?.additionalActions()) || [];

  const editActionsWidth =
    rowActions.onSave && !rowActions.hideEdit ? defaultActionColumnWidth : 0;
  const additionalActionsWidth = rowActions?.additionalActions
    ? additionalActions.reduce((acc, params) => {
        let result = acc;
        if (typeof params.width === 'number') {
          result += params.width;
        } else {
          result += additionalActionWidth;
        }
        return result;
      }, 0)
    : 0;

  return editActionsWidth + additionalActionsWidth;
};
