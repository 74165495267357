import qs from 'qs';
import { ISortBy, TCommonParams } from 'react-ui-kit-exante';

import { BS_API, PROD_API, TEST_API } from './constants';

export const paramsSerializer = (params: Record<string, unknown>) =>
  qs.stringify(params, {
    arrayFormat: 'comma',
    encode: true,
    skipNulls: true,
  });

export function isProd() {
  const isStage = window.location.host.includes('stage');
  const isLocalHost = window.location.host.includes('localhost');
  return !(isStage || isLocalHost);
}

export function getBrandingServiceUIBaseUrl(apiVersion = BS_API) {
  const bridgedApiUrl = window.BRANDING_UI?.api_urls?.BRANDING_UI;

  if (!bridgedApiUrl) {
    return `https://${isProd() ? PROD_API.BS : TEST_API.BS}${apiVersion}`;
  }

  return `https://${bridgedApiUrl}${apiVersion}`;
}

export function getNodeBackBaseUrl(apiVersion = BS_API) {
  const bridgedApiUrl = window.WEB_BO_UI?.api_urls?.BO;

  if (!bridgedApiUrl) {
    return `https://${isProd() ? PROD_API.BO : TEST_API.BO}${apiVersion}`;
  }

  return `https://${bridgedApiUrl}${apiVersion}`;
}

export const prepareParams = ({
  sorting,
  skip,
  page,
  ...rest
}: TCommonParams) => ({
  ...(sorting as ISortBy),
  offset: page,
  ...rest,
});
