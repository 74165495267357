import { useCallback, useState } from 'react';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from '../../../services/api/api.service';
import { getNodeBackBaseUrl } from '../../../services/api/helpers';
import {
  getAccessTokenFromCookie,
  getSessionIdFromCookie,
} from '../../../services/cookies/cookies.service';
import { ResponseDataWithPagination } from '../../../types/api';
import { BrandingBuildResponse } from '../../../types/brandingBuilds';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import { getFetchBrandingBuildsEndpoint } from '../endpoints';
import { GetBrandingBuildsParams } from '../types';

const nodeBackInstance = createInstance();

export const useGetBrandingBuildsQuery = () => {
  const nodeBackAPI = apiService(getNodeBackBaseUrl(), nodeBackInstance);
  const sessionToken = getSessionIdFromCookie();
  const token = getAccessTokenFromCookie();
  updateInstanceToken(nodeBackInstance, sessionToken, token);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchBrandingBuilds = useCallback(
    async ({
      platform,
      ...params
    }: GetBrandingBuildsParams): Promise<ResponseDataWithPagination<
      BrandingBuildResponse[]
    > | null> => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await nodeBackAPI.get({
          endPoint: getFetchBrandingBuildsEndpoint(platform),
          params,
        });

        return data;
      } catch (err) {
        setError((err as Error).message || UNKNOWN_ERROR_MESSAGE);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { error, loading, fetchBrandingBuilds };
};
