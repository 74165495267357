import { useCallback, useState } from 'react';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from '../../../services/api/api.service';
import { getNodeBackBaseUrl } from '../../../services/api/helpers';
import {
  getAccessTokenFromCookie,
  getSessionIdFromCookie,
} from '../../../services/cookies/cookies.service';
import { UNKNOWN_ERROR_MESSAGE } from '../constants';
import { getBuildStatusEndpoint } from '../endpoints';
import { TriggerBuildParams } from '../types';

const nodeBackInstance = createInstance();

export function useGetBrandingBuildStatus() {
  const nodeBackAPI = apiService(getNodeBackBaseUrl(), nodeBackInstance);
  const sessionToken = getSessionIdFromCookie();
  const token = getAccessTokenFromCookie();
  updateInstanceToken(nodeBackInstance, sessionToken, token);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchBrandingBuildStatus = useCallback(
    async (params: TriggerBuildParams) => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await nodeBackAPI.get({
          endPoint: getBuildStatusEndpoint(params),
        });

        return data;
      } catch (err) {
        setError((err as Error).message || UNKNOWN_ERROR_MESSAGE);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { error, loading, fetchBrandingBuildStatus };
}
