import { PanelGroup, Panel, styled, Table, Select } from 'react-ui-kit-exante';

export const StyledPanelGroup = styled(PanelGroup)(({ theme }) => ({
  gridTemplateColumns: '240px 1fr',
  gridGap: '0',
  borderTop: `1px solid ${theme.color.line.primary}`,
}));

export const StyledNavigation = styled(Panel)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  a: {
    fontSize: '14px',
    lineHeight: '16px',

    textDecoration: 'none',
    borderLeft: '2px solid transparent',
    padding: '5px 8px 4px 22px',

    '&:not(.active)': {
      color: theme.color.typo.action,
    },
    '&.active': {
      color: theme.color.typo.primary,
      backgroundColor: theme.color.bg.secondary,
      borderLeftColor: theme.color.icon.action,
    },
    '&:hover': {
      color: theme.color.typo.promo,
      textDecoration: 'underline',
    },
  },
}));

export const StyledPanel = styled(Panel)(({ theme }) => ({
  paddingTop: 0,
  borderLeft: `1px solid ${theme.color.line.primary}`,
}));

export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.color.notifications.success,
}));

export const StyledLinkWrapper = styled('span')(() => ({
  ':not(:last-child):after': {
    content: '", "',
  },
}));

export const StyledTable = styled(Table)(() => ({
  '& .HeaderTitle': {
    display: 'flex',
    alignItems: 'baseline',
  },
  '& header > div:last-child': {
    alignItems: 'baseline',
  },
  '& div[role="row"] > div[role="cell"]:last-child': {
    padding: 0,
    minWidth: '100px !important',
  },
  '& div[role="columnheader"]:last-child': {
    minWidth: '100px !important',
  },
  '& div[role="row"] > div[role="cell"]:last-child button': {
    fontSize: '12px',
  },
}));

export const StyledSelect = styled(Select)(() => ({
  marginLeft: '28px',
  width: '185px',
}));

export const StyledStatusFailed = styled('div')(({ theme }) => ({
  color: theme.color.notifications.error,
  display: 'flex',
  gap: '10px',
}));

export const StyledStatusProgress = styled('div')(({ theme }) => ({
  color: theme.color.notifications.warning,
  display: 'flex',
  gap: '10px',
}));

export const StyledStatusSuccess = styled('div')(({ theme }) => ({
  color: theme.color.notifications.success,
  display: 'flex',
  gap: '10px',
}));

export const StyledVersion = styled('span')(({ theme }) => ({
  color: theme.color.notifications.success,
}));
