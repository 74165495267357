import { IRow, CircleClockIcon, WarningCircleIcon } from 'react-ui-kit-exante';

import { BrandingBuildDesktopResponse } from 'types/brandingBuilds';

import {
  StyledLink,
  StyledLinkWrapper,
  StyledStatusProgress,
  StyledStatusFailed,
} from '../../../TradingTerminals.styled';

interface CellBuildStatusProps {
  row: IRow<BrandingBuildDesktopResponse>;
}

export const CellBuildStatus = ({
  row: {
    original: { error, status, buildArtifact },
  },
}: CellBuildStatusProps) => {
  if (status === 'error') {
    return (
      <StyledStatusFailed>
        <WarningCircleIcon /> <span>{error}</span>
      </StyledStatusFailed>
    );
  }

  if (status === 'created') {
    return (
      <StyledStatusProgress>
        <CircleClockIcon /> <span>In progress</span>
      </StyledStatusProgress>
    );
  }

  return (
    <div>
      {'windows' in (buildArtifact || {}) ? (
        <StyledLinkWrapper>
          <StyledLink
            href={buildArtifact?.windows}
            target="_blank"
            rel="noreferrer"
          >
            Windows
          </StyledLink>
        </StyledLinkWrapper>
      ) : null}

      {'linux' in (buildArtifact || {}) ? (
        <StyledLinkWrapper>
          <StyledLink
            href={buildArtifact?.linux}
            target="_blank"
            rel="noreferrer"
          >
            Linux
          </StyledLink>
        </StyledLinkWrapper>
      ) : null}

      {'macos' in (buildArtifact || {}) ? (
        <StyledLinkWrapper>
          <StyledLink
            href={buildArtifact?.macos}
            target="_blank"
            rel="noreferrer"
          >
            macOS
          </StyledLink>
        </StyledLinkWrapper>
      ) : null}
    </div>
  );
};
