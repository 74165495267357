import { format } from 'date-fns';
import { IRow } from 'react-ui-kit-exante';

import { BrandingBuildDesktopResponse } from 'types/brandingBuilds';

interface CellReleasedDateProps {
  row: IRow<BrandingBuildDesktopResponse>;
  value: string;
}

export const CellReleasedDate = ({
  value,
  row: {
    original: { status },
  },
}: CellReleasedDateProps) => {
  if (status === 'released') {
    return <span>{format(new Date(value), 'yyyy-MM-dd HH:mm')}</span>;
  }

  return '–';
};
