import { ResponseDataWithPagination } from 'types/api';
import {
  BrandingBuildPlatform,
  BrandingBuildResponse,
} from 'types/brandingBuilds';

export const ALL_BRANDINGS = 'ALL';
export const UNKNOWN_ERROR_MESSAGE = 'Something went wrong';

export const TABLE_IDS: Record<BrandingBuildPlatform, string> = {
  desktop: 'trading-terminals-desktop-platform',
  ios: 'trading-terminals-mobile-pro-android',
  android: 'trading-terminals-mobile-pro-ios',
};

export const TABLE_TITLES: Record<BrandingBuildPlatform, string> = {
  desktop: 'Desktop Platform',
  ios: 'Mobile Pro iOS',
  android: 'Mobile Pro Android',
};

export const EMPTY_DATA: ResponseDataWithPagination<BrandingBuildResponse[]> = {
  data: [],
  pagination: { total: 0 },
};
