import { useTranslator } from '../../contexts/TranslationContext';

import { ColumnSelect } from './ColumnSelect';
import {
  HeaderButtonsStyled,
  InfoBlockStyled,
  LineInfoStyled,
  TableTopPanelStyled,
  TotalLineInfoStyled,
} from './Table.styled';
import { TableTopPanelProps } from './types';

export const TableTopPanel = <Data extends object>({
  startLine,
  endLine,
  totalLines,
  resetColumns,
  allColumns,
  visibleColumnKeys,
  handleVisibleColumnKeysChange,
  isHiddenColumnSelect,
  showTableInfo,
  isHiddenColumnSelectControls,
}: TableTopPanelProps<Data>) => {
  const { t } = useTranslator();

  return (
    <TableTopPanelStyled className="TableTopPanel">
      {showTableInfo && (
        <>
          <InfoBlockStyled className="TableInfoBlock">
            {totalLines === 0 ? (
              t('ui__table__info__nothing_to_show')
            ) : (
              <>
                {t('ui__table__info__start_pfx')}
                <LineInfoStyled className="TableLineInfo">
                  {startLine}
                </LineInfoStyled>
                {t('ui__table__info__end_pfx')}
                <LineInfoStyled className="TableLineInfo">
                  {endLine}
                </LineInfoStyled>
                {t('ui__table__info__total_pfx')}
                <TotalLineInfoStyled className="TableTotalLineInfo">
                  {totalLines}
                </TotalLineInfoStyled>
              </>
            )}
          </InfoBlockStyled>
          <HeaderButtonsStyled className="TableHeaderButtons">
            {!isHiddenColumnSelect && (
              <ColumnSelect<Data>
                columns={allColumns}
                selected={visibleColumnKeys}
                onChange={handleVisibleColumnKeysChange}
                onReset={resetColumns}
                isHiddenColumnSelectControls={isHiddenColumnSelectControls}
              />
            )}
          </HeaderButtonsStyled>
        </>
      )}
    </TableTopPanelStyled>
  );
};
