import * as cookies from 'js-cookie';

import {
  SESSION_ID_COOKIE_KEY,
  SESSION_GOOGLE_COOKIE_KEY,
  ACCESS_TOKEN_COOKIE_KEY,
} from '../authorization/const';

export function getSessionIdFromCookie(sessionKey?: string) {
  return cookies.get(SESSION_ID_COOKIE_KEY || sessionKey);
}

export function getAccessTokenFromCookie(accessTokenKey?: string) {
  const token = cookies.get(
    SESSION_GOOGLE_COOKIE_KEY || ACCESS_TOKEN_COOKIE_KEY || accessTokenKey,
  );

  return token && JSON.parse(token);
}
