import { get } from 'lodash';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { HeaderGroup, IdType } from 'react-table';

import { LocalStorage } from 'helpers';

import { EMPTY_FUNCTION } from '../../consts';
import { KitTheme, useTheme } from '../../theme';
import { Tooltip } from '../Tooltip';

import { SortButton } from './SortButton/SortButton';
import {
  CellStyled,
  CellWithTooltipWrapper,
  CellWrapperStyled,
  HeaderCellWrapperStyled,
  HeaderStyled,
  HeadRowStyled,
  OverflowedMask,
  ResizerStyled,
  SortIconContainerStyled,
  SubHeaderStyled,
} from './Table.styled';
import {
  IDraggableColumnHeaderProps,
  TableHeadProps,
  TableViewParamsSchema,
  TExtendedColumnWithAdditionalFields,
  TPropColumnGetter,
} from './types';

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSProperties,
  style: CSSProperties | undefined,
) => ({
  userSelect: 'none',
  ...style,
  ...draggableStyle,
  opacity: isDragging ? 0.5 : 1,
});

const COLUMN_ALIGN_TO_JUSTIFY = {
  center: 'center',
  right: 'flex-end',
  left: 'flex-start',
};

const DraggableColumnHeader = <Data extends object, Theme = KitTheme>({
  column,
  getHeaderPropsExternal,
  getColumnPropsExternal,
  isLoading,
  provided,
  snapshot,
  setActiveResize,
  cellStyle,
  subColumns,
  isTableWithGroupedHeaders,
}: IDraggableColumnHeaderProps<Data, Theme>) => {
  const theme = useTheme();
  const cellRef = useRef<HTMLDivElement>(null);
  const [overflowed, setOverflowed] = useState<boolean | null>(false);

  const columnHeader = column.Header?.toString();

  const getIsOverflowed = () => {
    return (
      cellRef.current &&
      cellRef.current.scrollWidth > cellRef.current.clientWidth
    );
  };

  const handleMouseDown = () => {
    setActiveResize(true);
    setOverflowed(getIsOverflowed());
  };
  const handleMouseUp = () => {
    setActiveResize(false);
    setOverflowed(getIsOverflowed());
  };

  useEffect(() => {
    setOverflowed(getIsOverflowed());
  }, []);

  const { id: columnId } = column;
  const isActionsColumn = columnId === 'actions';
  const sortIcon = (
    <SortButton isSorted={column.isSorted} desc={column.isSortedDesc} />
  );

  const headerCellProps = column.getHeaderProps([
    column.getSortByToggleProps?.(),
    // @ts-expect-error -- we expect external theme to inherit KitTheme
    getHeaderPropsExternal(column, theme),
    // @ts-expect-error -- we expect external theme to inherit KitTheme
    getColumnPropsExternal(column, theme),
  ]);

  const headerTooltip = headerCellProps.headerTooltip;
  const columnTooltip = headerTooltip ?? columnHeader;
  const hasSubColumns = Boolean(subColumns?.length);

  const memoizedColumn = useMemo(
    () => (
      <HeaderCellWrapperStyled hasSubColumns={hasSubColumns}>
        {column.render(column.HeaderWithIcon ? 'HeaderWithIcon' : 'Header')}
      </HeaderCellWrapperStyled>
    ),
    [column, hasSubColumns],
  );

  const additionalCellStyles = {
    ...(cellStyle || {}),
    ...headerCellProps?.style,
    position:
      isActionsColumn && !isLoading
        ? 'sticky'
        : headerCellProps?.style?.position,
    width: '100%',
    justifyContent: COLUMN_ALIGN_TO_JUSTIFY[column.align || 'left'],
    overflow: 'hidden',
    display: 'inline-flex',
  };

  const containerStyles = {
    ...getItemStyle(
      snapshot.isDragging,
      provided.draggableProps.style,
      column.getHeaderProps().style,
    ),
    height: '100%',
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...column.getHeaderProps()}
      style={containerStyles}
    >
      <CellWrapperStyled>
        <CellStyled
          ref={cellRef}
          isStickyCell={isActionsColumn && !isLoading}
          isHeadCell
          data-test-id="table__head--cell"
          hasSubColumns={hasSubColumns}
          hasBorders={isTableWithGroupedHeaders}
          {...headerCellProps}
          style={additionalCellStyles}
        >
          {typeof column.Header === 'string' &&
          (overflowed || headerTooltip) ? (
            <CellWithTooltipWrapper hasSubColumns={hasSubColumns}>
              <Tooltip title={columnTooltip ?? ''} placement="top-start">
                {memoizedColumn}
              </Tooltip>
            </CellWithTooltipWrapper>
          ) : (
            memoizedColumn
          )}

          <OverflowedMask />

          {column.canSort ? (
            <SortIconContainerStyled>{sortIcon}</SortIconContainerStyled>
          ) : null}

          {hasSubColumns ? (
            <SubHeaderStyled isTopGroupHeader={Boolean(columnHeader)}>
              {subColumns}
            </SubHeaderStyled>
          ) : null}
        </CellStyled>
      </CellWrapperStyled>

      <ResizerStyled
        onMouseEnter={handleMouseDown}
        onMouseLeave={handleMouseUp}
        {...column.getResizerProps()}
      />
    </div>
  );
};

type TDraggableItemProps<Data extends object, Theme = KitTheme> = {
  index: number;
  column: HeaderGroup<Data>;
  saveColumnOrder: boolean;
  activeResize: boolean;
  setActiveResize: (value: boolean) => void;
  columnOrder: string[];
  setColumnOrder: (
    updater: ((columnOrder: IdType<Data>[]) => IdType<Data>[]) | IdType<Data>[],
  ) => void;
  getHeaderPropsExternal: TPropColumnGetter<Data, Theme>;
  getColumnPropsExternal: TPropColumnGetter<Data, Theme>;
  isLoading: boolean;
  storage: LocalStorage<TableViewParamsSchema>;
  allColumns: TExtendedColumnWithAdditionalFields<Data>[];
  isTableWithGroupedHeaders?: boolean;
};

const DraggableItem = <Data extends object, Theme = KitTheme>({
  index,
  column,
  ...props
}: TDraggableItemProps<Data, Theme>) => {
  const {
    saveColumnOrder,
    activeResize,
    setActiveResize,
    columnOrder,
    setColumnOrder,
    getHeaderPropsExternal,
    getColumnPropsExternal,
    isLoading,
    storage,
    allColumns,
    isTableWithGroupedHeaders,
  } = props;

  const renderDraggableColumnHeader = ({
    provided,
    snapshot,
    ...rest
  }: Record<string, unknown> & {
    provided: IDraggableColumnHeaderProps<Data>['provided'];
    snapshot: IDraggableColumnHeaderProps<Data>['snapshot'];
  }) => {
    return (
      <DraggableColumnHeader
        setActiveResize={setActiveResize}
        columnOrder={columnOrder}
        key={column.id}
        column={column}
        setColumnOrder={setColumnOrder}
        getHeaderPropsExternal={getHeaderPropsExternal}
        getColumnPropsExternal={getColumnPropsExternal}
        isLoading={isLoading}
        storage={storage}
        saveColumnOrder={saveColumnOrder}
        cellStyle={{
          ...(get(allColumns, `${index}.cellStyle`) || {}),
        }}
        // dnd props
        provided={provided}
        snapshot={snapshot}
        isTableWithGroupedHeaders={isTableWithGroupedHeaders}
        {...rest}
      />
    );
  };
  if ('columns' in column || !column.headers) {
    const subColumns = !column.headers
      ? null
      : column.headers.map((subColumn, subColumnIndex) => {
          return (
            <DraggableItem
              key={subColumn.id}
              column={subColumn}
              index={subColumnIndex}
              {...props}
              isTableWithGroupedHeaders
            />
          );
        });

    return (
      <Draggable
        key={column.id}
        draggableId={column.id}
        index={index}
        isDragDisabled={!saveColumnOrder || activeResize}
      >
        {(provided, snapshot) =>
          renderDraggableColumnHeader({
            provided,
            snapshot,
            subColumns,
          })
        }
      </Draggable>
    );
  }

  const [singleHeader] = column.headers;

  return (
    <DraggableItem
      key={singleHeader.id}
      column={singleHeader}
      index={0}
      {...props}
    />
  );
};

export const TableHead = <Data extends object, Theme = KitTheme>({
  setColumnOrder,
  headerGroups,
  isPinnedHeader = false,
  getHeaderPropsExternal,
  getColumnPropsExternal,
  isLoading,
  columnOrder,
  storage,
  saveColumnOrder = false,
  allColumns,
}: TableHeadProps<Data, Theme>) => {
  const [activeResize, setActiveResize] = useState(false);
  const currentColOrder = useRef<string[]>();
  const countOfLayers = headerGroups.length;
  const defaultRowHeight = 40;
  const [headerGroup] = headerGroups;

  if (headerGroups.length === 0 || !headerGroup) {
    return null;
  }

  return (
    <HeaderStyled
      isPinnedHeader={isPinnedHeader}
      height={defaultRowHeight}
      className="TableHeader"
    >
      <DragDropContext
        key={headerGroup.getHeaderGroupProps().key}
        onDragStart={() => {
          currentColOrder.current = [...columnOrder];
        }}
        onDragUpdate={(dragUpdateObj) => {
          const sIndex = dragUpdateObj.source.index;
          const dIndex =
            dragUpdateObj.destination && dragUpdateObj.destination.index;

          if (currentColOrder.current && typeof dIndex === 'number') {
            const colOrder = [...currentColOrder.current];

            colOrder.splice(sIndex, 1);
            colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);

            setColumnOrder(colOrder);
            storage.set('columnOrder', colOrder);
          }
        }}
        onDragEnd={EMPTY_FUNCTION}
      >
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided) => (
            <HeadRowStyled
              {...headerGroup.getHeaderGroupProps()}
              data-test-id="table__head--row"
              ref={droppableProvided.innerRef}
              hasBorders={countOfLayers > 1}
              style={{
                ...headerGroup.getHeaderGroupProps().style,
              }}
              {...droppableProvided.droppableProps}
            >
              {headerGroup.headers?.map((column, index) => {
                return (
                  <DraggableItem
                    key={column.id}
                    column={column}
                    index={index}
                    saveColumnOrder={saveColumnOrder}
                    activeResize={activeResize}
                    setActiveResize={setActiveResize}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    getHeaderPropsExternal={getHeaderPropsExternal}
                    getColumnPropsExternal={getColumnPropsExternal}
                    isLoading={isLoading}
                    storage={storage}
                    allColumns={allColumns}
                    isTableWithGroupedHeaders={countOfLayers > 1}
                  />
                );
              })}
              {droppableProvided.placeholder}
            </HeadRowStyled>
          )}
        </Droppable>
      </DragDropContext>
    </HeaderStyled>
  );
};
