import { defaultTheme as UiKitTheme, createTheme } from 'react-ui-kit-exante';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    color: Record<string, any>;
    font: Record<string, any>;
    size: Record<string, any>;
  }
}

export const theme = createTheme(UiKitTheme);
