import { IconButton } from '../../../Button/IconButton';

import { ContextMenuContainer, MenuItem } from './RowContextMenu.styled';
import { TCustomContextMenuProps } from './types';

export const CustomContextMenu = ({
  isVisible,
  handleCopy,
  handlePaste,
  position,
  copyText = 'Copy row to clipboard',
  pastText = 'Paste row from clipboard',
  handleClose,
}: TCustomContextMenuProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <ContextMenuContainer
      open={isVisible}
      anchorReference="anchorPosition"
      anchorPosition={{ top: position.top, left: position.left }}
      onClose={handleClose}
      autoFocus={false}
    >
      <MenuItem onClick={handleCopy} disableRipple>
        <IconButton
          applyStates={false}
          iconColor="secondary"
          iconName="CopyIcon"
          iconSize={16}
          type="button"
        />
        {copyText}
      </MenuItem>
      <MenuItem onClick={handlePaste} disableRipple>
        <IconButton
          applyStates={false}
          iconColor="secondary"
          iconName="CopyPasteIcon"
          iconSize={16}
          type="button"
        />
        {pastText}
      </MenuItem>
    </ContextMenuContainer>
  );
};
