import { IRow, CircleClockIcon } from 'react-ui-kit-exante';

import { BrandingBuildDesktopResponse } from 'types/brandingBuilds';

import {
  StyledStatusProgress,
  StyledStatusSuccess,
} from '../../../TradingTerminals.styled';

interface CellDeployStatusProps {
  row: IRow<BrandingBuildDesktopResponse>;
}

export const CellDeployStatus = ({
  row: {
    original: { status },
  },
}: CellDeployStatusProps) => {
  if (status === 'release-started') {
    return (
      <StyledStatusProgress>
        <CircleClockIcon /> <span>In progress</span>
      </StyledStatusProgress>
    );
  }

  if (status === 'released') {
    return (
      <StyledStatusSuccess>
        <span>Success</span>
      </StyledStatusSuccess>
    );
  }

  return '–';
};
