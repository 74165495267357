import { forwardRef } from 'react';

import SaveIcon from '../../../Icons/components/Save';
import { DefaultThemeProvider } from '../../../theme';
import { Skeleton } from '../../Skeleton';

import {
  CheckboxContainerStyled,
  CheckboxMessageStyled,
  ControlStyled,
  IconStyled,
  LabelStyled,
  SkeletonContainerStyled,
} from './Checkbox.styled';
import { IUICheckboxProps } from './types';

export const Checkbox = forwardRef<HTMLInputElement, IUICheckboxProps>(
  (
    {
      checked,
      color = 'primary',
      controlProps,
      disabled,
      label = '',
      onChange,
      warning = false,
      error = false,
      message,
      showSkeleton,
      ...props
    },
    ref,
  ) => {
    return (
      <DefaultThemeProvider>
        <CheckboxContainerStyled>
          {showSkeleton && (
            <SkeletonContainerStyled>
              <Skeleton width={16} height={16} />
              <Skeleton width={100} height={16} />
            </SkeletonContainerStyled>
          )}
          {!showSkeleton && (
            <LabelStyled
              checked={checked}
              control={
                <ControlStyled
                  checked={checked}
                  checkedIcon={
                    <IconStyled
                      checked={!!checked}
                      color={color}
                      warning={warning}
                      error={error}
                    >
                      <SaveIcon />
                    </IconStyled>
                  }
                  disabled={disabled}
                  icon={
                    <IconStyled
                      checked={!!checked}
                      color={color}
                      warning={warning}
                      error={error}
                    />
                  }
                  inputRef={ref}
                  onChange={onChange}
                  disableRipple
                  {...controlProps}
                />
              }
              disabled={disabled}
              label={label}
              {...props}
            />
          )}
          {!showSkeleton && message && (
            <CheckboxMessageStyled error={error} warning={warning}>
              {message}
            </CheckboxMessageStyled>
          )}
        </CheckboxContainerStyled>
      </DefaultThemeProvider>
    );
  },
);

Checkbox.displayName = 'Checkbox';
