import { flatMap } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  IconButton,
  Modal,
  Notification,
  useModal,
  useTableData,
} from 'react-ui-kit-exante';
import { IOnFetchArguments } from 'react-ui-kit-exante/build/Components/Table/hooks/types';

import { calculateCountOfPages, useRepeatedAction } from 'shared/utils';
import {
  BrandingBuildAllPlatformsResponse,
  BrandingBuildPlatform,
} from 'types/brandingBuilds';

import { StyledPanel, StyledTable } from '../../TradingTerminals.styled';
import { ALL_BRANDINGS } from '../../constants';
import {
  useCancelBuildMutation,
  useGetBrandingBuildsAllPlatformsQuery,
  useGetBrandingBuildStatus,
  useReleaseBuildMutation,
} from '../../hooks';

import { columns } from './columns';

export const All = () => {
  const tableId = 'trading-terminals-all';

  const modalCancel = useModal();
  const modalDeploy = useModal();

  const { fetchBrandingBuilds } = useGetBrandingBuildsAllPlatformsQuery();
  const { cancelBuildMutation } = useCancelBuildMutation();
  const { releaseBuildMutation } = useReleaseBuildMutation();
  const { fetchBrandingBuildStatus } = useGetBrandingBuildStatus();

  const refBranding = useRef<string>(ALL_BRANDINGS);
  const refPlatform = useRef<BrandingBuildPlatform>('desktop');

  const getBrandingBuilds = useCallback(
    async ({ params: { limit, skip } }: IOnFetchArguments) => {
      // first step: we need to get a list of brandings for which we need to update the status
      const responseForUpdateStatus = await fetchBrandingBuilds({
        skip: 0,
        limit: Infinity,
      });

      let brandingsForUpdateStatusInfo: Array<[string, BrandingBuildPlatform]> =
        flatMap(responseForUpdateStatus?.data || [], (item) =>
          item.status === 'created' || item.status === 'release-started'
            ? [[item.branding, item.platform]]
            : [],
        );

      brandingsForUpdateStatusInfo = Array.from(
        new Set(brandingsForUpdateStatusInfo),
      );

      await Promise.all(
        brandingsForUpdateStatusInfo.map(([branding, platform]) =>
          fetchBrandingBuildStatus({
            branding,
            platform,
          }),
        ),
      );

      // second step: getting table data
      const response = await fetchBrandingBuilds({ limit, skip });

      return response;
    },
    [fetchBrandingBuilds],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getBrandingBuilds },
      tableId,
    }),
    [getBrandingBuilds, tableId],
  );

  const { data, fetchData, isLoading, limit, page, setLimit, setPage } =
    useTableData(tableDataArgs);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const onCancelClickHandler = useCallback(
    ({ branding, platform }: BrandingBuildAllPlatformsResponse) => {
      refBranding.current = branding;
      refPlatform.current = platform;

      modalCancel.onOpen();
    },
    [modalCancel],
  );

  const onDeployClickHandler = useCallback(
    ({ branding, platform }: BrandingBuildAllPlatformsResponse) => {
      refBranding.current = branding;
      refPlatform.current = platform;

      modalDeploy.onOpen();
    },
    [modalDeploy],
  );

  const rowActions = useMemo(
    () => ({
      show: true,
      hideEdit: true,
      additionalActions: [
        {
          label: (
            <IconButton
              iconName="CircleCloseIcon"
              iconColor="ghost"
              iconSize={16}
              label="Cancel"
            />
          ),
          onClick: onCancelClickHandler,
          show: (_: unknown, values: BrandingBuildAllPlatformsResponse) =>
            values.status === 'created',
        },
        {
          label: (
            <IconButton
              iconName="PlayIcon"
              iconColor="action"
              iconSize={16}
              label="Deploy"
            />
          ),
          onClick: onDeployClickHandler,
          show: (_: unknown, values: BrandingBuildAllPlatformsResponse) =>
            values.status === 'done' && values.platform === 'desktop',
        },
      ],
    }),
    [modalCancel.onOpen],
  );

  const { startRepeatedAction, cancelRepeatedAction } = useRepeatedAction({
    action: fetchData,
    onNotify: () => {
      Notification.warning({
        title: 'Branding builds will be refreshed in 10 seconds',
      });
    },
  });

  const deployBrandingBuild = useCallback(async () => {
    cancelRepeatedAction();
    const response = await releaseBuildMutation({
      branding: refBranding.current,
      platform: refPlatform.current,
    });

    if (response) {
      fetchData();

      Notification.success({
        title: 'Branding build has been sent for release successfully',
      });

      modalDeploy.onClose();
    } else {
      Notification.error({
        title: 'An error occurred while sending for release the branding build',
      });
    }

    startRepeatedAction();
  }, [
    cancelRepeatedAction,
    fetchData,
    releaseBuildMutation,
    startRepeatedAction,
  ]);

  const cancelBrandingBuild = useCallback(async () => {
    cancelRepeatedAction();

    const response = await cancelBuildMutation({
      branding: refBranding.current,
      platform: refPlatform.current,
    });

    if (response) {
      fetchData();

      Notification.success({
        title: 'Branding build has been canceled successfully',
      });

      modalCancel.onClose();
    } else {
      Notification.error({
        title: 'An error occurred while canceling the branding build',
      });
    }

    startRepeatedAction();
  }, [
    cancelBuildMutation,
    cancelRepeatedAction,
    modalCancel.onClose,
    fetchData,
    startRepeatedAction,
  ]);

  useEffect(() => {
    startRepeatedAction();
  }, []);

  return (
    <StyledPanel disableBodyPaddings>
      <StyledTable
        columns={columns}
        data={data?.data || []}
        defaultSortBy={[]}
        hasPagination
        isFlexLayout
        isLoading={isLoading}
        // TODO in UI Kit `values` is just object without Generic
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rowActions={rowActions}
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        tableId={tableId}
        title="All"
      />

      <Modal
        isOpened={modalCancel.isOpened}
        onClose={modalCancel.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: cancelBrandingBuild,
        }}
      >
        <div>Do you want to cancel this build?</div>
      </Modal>

      <Modal
        isOpened={modalDeploy.isOpened}
        onClose={modalDeploy.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: deployBrandingBuild,
        }}
      >
        <div>Do you want to deploy this build?</div>
      </Modal>
    </StyledPanel>
  );
};
