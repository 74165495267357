import { useCallback, useEffect, useState } from 'react';

import { useNodeBackService } from '../../../services/NodeBack.service';

export function useBrandingList() {
  const [brandingOptions, setBrandingOptions] = useState([]);

  const { getCurrentBrandings } = useNodeBackService();

  const fetchBrandingOptions = useCallback(async () => {
    const currentBrandings = await getCurrentBrandings();

    setBrandingOptions(
      currentBrandings.map((branding: string) => ({
        value: branding,
        label: branding,
      })),
    );
  }, []);

  useEffect(() => {
    fetchBrandingOptions();
  }, []);

  return brandingOptions;
}
