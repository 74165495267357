import { IRow } from 'react-ui-kit-exante';

import { BrandingBuildDesktopResponse } from 'types/brandingBuilds';

interface CellVersionProps {
  value: string;
  row: IRow<BrandingBuildDesktopResponse>;
}

export const CellVersion = ({
  value,
  row: {
    original: { status },
  },
}: CellVersionProps) => {
  if (status === 'released') {
    return value;
  }

  return '–';
};
