import axios, { AxiosInstance, AxiosPromise, Method, CancelToken } from 'axios';

import {
  getAccessTokenFromCookie,
  getSessionIdFromCookie,
} from '../cookies/cookies.service';

import { Headers, Methods } from './constants';
import { paramsSerializer } from './helpers';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 150000;

type TOptions = {
  contentType?: string;
  data?: any;
  method?: Method;
  endPoint?: string;
  url?: string;
  headers?: any;
  sessionToken?: string;
  params?: any;
  cancelToken?: CancelToken;
};

export const apiService = (baseURL: string, service: AxiosInstance = axios) => {
  const getHeaders = (contentType: string, sessionToken?: string) => {
    const headers: any = {
      [Headers.CONTENT_TYPE]: contentType,
    };

    if (sessionToken) {
      headers[Headers.X_AUTH_SESSIONID] = sessionToken;
    }

    return headers;
  };

  const request: (options: TOptions) => AxiosPromise = ({
    contentType = 'application/json',
    endPoint = '',
    headers,
    method,
    sessionToken,
    url = baseURL,
    ...options
  }) =>
    service({
      url: `${url}${endPoint}`,
      method,
      headers: {
        ...getHeaders(contentType, sessionToken),
        ...headers,
      },
      ...options,
      paramsSerializer,
    });

  const get: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.GET,
    });

  const head: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.HEAD,
    });

  const post = (options: TOptions) =>
    request({ method: Methods.POST, ...options });
  const put = (options: TOptions) =>
    request({ method: Methods.PUT, ...options });
  const patch = (options: TOptions) =>
    request({ method: Methods.PATCH, ...options });

  return {
    delete: (options: TOptions) =>
      request({ method: Methods.DELETE, ...options }),
    get,
    post,
    put,
    patch,
    head,
  };
};

export const createInstance = (
  sessionId: string | boolean | undefined = getSessionIdFromCookie(),
  authToken: string | boolean | undefined = getAccessTokenFromCookie()
    ?.access_token,
) => {
  const headers: {
    [Headers.X_AUTH_SESSIONID]?: string;
    [Headers.AUTHORIZATION]?: string;
  } = {};

  if (sessionId && typeof sessionId === 'string') {
    headers[Headers.X_AUTH_SESSIONID] = sessionId;
  }
  if (authToken && typeof sessionId === 'string') {
    headers[Headers.AUTHORIZATION] = `Bearer ${authToken}`;
  }

  return axios.create({ headers });
};

export const updateInstanceToken = (
  instance: AxiosInstance,
  sessionToken?: string,
  tokens?: Record<string, unknown>,
) => {
  const instanceSessionId =
    instance?.defaults?.headers[Headers.X_AUTH_SESSIONID];

  const instanceAccessToken =
    instance?.defaults?.headers[Headers.AUTHORIZATION] &&
    instance?.defaults?.headers[Headers.AUTHORIZATION] !== 'Bearer undefined'
      ? instance?.defaults?.headers[Headers.AUTHORIZATION]
      : null;

  const accessToken = tokens?.access_token;

  // TODO: Remove when full transition to access token happens
  if (
    (!instanceSessionId && sessionToken) ||
    (instanceSessionId && instanceSessionId !== sessionToken)
  ) {
    // eslint-disable-next-line no-param-reassign
    delete instance.defaults.headers[Headers.AUTHORIZATION];
    // eslint-disable-next-line no-param-reassign
    instance.defaults.headers[Headers.X_AUTH_SESSIONID] = sessionToken;
  } else if (
    (!instanceAccessToken && accessToken) ||
    (instanceAccessToken && instanceAccessToken !== accessToken)
  ) {
    // eslint-disable-next-line no-param-reassign
    delete instance.defaults.headers[Headers.X_AUTH_SESSIONID];
    // eslint-disable-next-line no-param-reassign
    instance.defaults.headers[Headers.AUTHORIZATION] = `Bearer ${accessToken}`;
  }
};
