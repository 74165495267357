import { blockNonNativeProps } from '../../helpers';
import { styled } from '../../theme';

import { TRANSIENT_PROPS } from './constants';
import { IPanelGroupProps } from './types';

export const Root = styled('section', {
  shouldForwardProp: blockNonNativeProps(TRANSIENT_PROPS),
})((props: IPanelGroupProps) => {
  const css: Partial<React.CSSProperties> = {
    display: 'grid',
    gridGap: '8px',
    justifyContent: 'left',
  };

  if (props.panelsPerLine) {
    css.gridTemplateColumns = `repeat(${props.panelsPerLine}, 1fr)`;
  } else if (props.equalWidth) {
    css.gridAutoColumns = `minmax(0, 1fr)`;
    css.gridAutoFlow = 'column';
  }

  return css;
});
