import { CellProps } from 'react-table';

import type { TSetFilterType, TSetSavedWidth } from '../columnMappers';
import type { IColumn, RowActions } from '../types';
import { OnSaveEditableRow } from '../types';

import { calculateActionsColumnWidth } from './calculateActionsColumnWidth';
import { getColumnsWidths } from './getColumnsWidths';
import { renderActionColumnCell } from './renderActionsColumnCell';

export const getEnrichedColumns = <Data extends object>({
  tableColumns,
  tableLSWidthKey,
  rowActions,
  setSavedWidth,
  setFilterType,
}: {
  tableColumns: IColumn<Data>[];
  tableLSWidthKey: string;
  rowActions?: RowActions<Data>;
  setSavedWidth: TSetSavedWidth;
  setFilterType: TSetFilterType;
}) => {
  const savedWidths = getColumnsWidths(tableLSWidthKey);

  const mappedColumns = tableColumns.map((column) => {
    let mappedColumn = { ...column };
    if (savedWidths) {
      mappedColumn = setSavedWidth(mappedColumn, savedWidths);
    }
    mappedColumn = setFilterType(mappedColumn);
    return mappedColumn;
  });

  if (rowActions && rowActions.show) {
    const actionsColumnWidth = calculateActionsColumnWidth(rowActions);

    const actionColumn: IColumn<Data> = {
      Header: 'Actions',
      accessor: 'actions' as keyof Data,
      width: actionsColumnWidth,
      printable: false,
      disableFilters: true,
      disableSortBy: true,
      hideInMenu: true,
      Cell: (forwardedProps: CellProps<Data>) => {
        const onSaveHandler = (previousData: Data, updatedData: Data) => {
          const { state, setAllFilters } = forwardedProps;

          return (rowActions.onSave as OnSaveEditableRow<Data>)(
            previousData,
            updatedData,
          ).finally(() => {
            if (state.filters?.length) {
              setAllFilters(state.filters);
            }
          });
        };

        const onCancelEditingHandler = (
          previousData: Data,
          updatedData: Data,
        ) => {
          return (rowActions.onCancel as OnSaveEditableRow<Data>)(
            previousData,
            updatedData,
          );
        };

        return renderActionColumnCell({
          rowId: forwardedProps.row.id,
          previousValues: forwardedProps.row.original,
          onSaveHandler,
          onCancelEditingHandler: rowActions?.onCancel
            ? onCancelEditingHandler
            : undefined,
          additionalRowActions: rowActions?.additionalActions ?? [],
          isEditedRow: rowActions.isEditedRow,
          values: forwardedProps.row.original,
          columns: forwardedProps.allColumns,
          order: rowActions.order,
          hideEdit: rowActions.hideEdit,
        });
      },
    };
    mappedColumns.push(actionColumn);
  }

  return mappedColumns;
};
