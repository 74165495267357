import {
  CellBuildLink,
  CellDeployStatus,
  CellPlatform,
  CellReleasedDate,
  CellVersion,
} from './components';

export const columns = [
  {
    Header: 'Platform',
    accessor: 'platform',
    Cell: CellPlatform,
    disableSortBy: true,
  },
  {
    Header: 'Branding',
    accessor: 'branding',
    disableSortBy: true,
  },
  {
    Cell: CellBuildLink,
    Header: 'Build link',
    disableSortBy: true,
  },
  {
    Cell: CellDeployStatus,
    Header: 'Deploy status',
    disableSortBy: true,
  },
  {
    Header: 'Version',
    accessor: 'appVersion',
    disableSortBy: true,
    Cell: CellVersion,
  },
  {
    Cell: CellReleasedDate,
    accessor: 'updatedAt',
    Header: 'Release date',
    disableSortBy: true,
  },
];
