import {
  CellBuildStatus,
  CellDeployStatus,
  CellVersion,
  CellReleasedDate,
} from './components';

export const columns = [
  {
    Header: 'Branding',
    accessor: 'branding',
    disableSortBy: true,
  },
  {
    Cell: CellBuildStatus,
    Header: 'Build link',
    disableSortBy: true,
  },
  {
    Cell: CellDeployStatus,
    Header: 'Deploy status',
    disableSortBy: true,
  },
  {
    Header: 'Version',
    accessor: 'appVersion',
    disableSortBy: true,
    Cell: CellVersion,
  },
  {
    Header: 'Released date',
    accessor: 'updatedAt',
    disableSortBy: true,
    Cell: CellReleasedDate,
  },
];
