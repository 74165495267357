import { AxiosResponse } from 'axios';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from './api/api.service';
import { Endpoint } from './api/constants';
import { getNodeBackBaseUrl } from './api/helpers';
import {
  getSessionIdFromCookie,
  getAccessTokenFromCookie,
} from './cookies/cookies.service';

const nodeBackInstance = createInstance();

export const useNodeBackService = () => {
  const nodeBackAPI = apiService(getNodeBackBaseUrl(), nodeBackInstance);
  const sessionToken = getSessionIdFromCookie();
  const token = getAccessTokenFromCookie();
  updateInstanceToken(nodeBackInstance, sessionToken, token);

  const triggerAtpBuild = async (brandingName: string) => {
    const result: AxiosResponse<any> = await nodeBackAPI.post({
      endPoint: `${Endpoint.Brandings}/${brandingName}/builds/trigger`,
    });

    return result.data;
  };

  const triggerAtpBuildRelease = async (brandingName: string) => {
    const result: AxiosResponse<any> = await nodeBackAPI.post({
      endPoint: `${Endpoint.Brandings}/${brandingName}/releases/trigger`,
    });

    return result.data;
  };

  const getBuildStatus = async (brandingName: string) => {
    const result: AxiosResponse<any> = await nodeBackAPI.get({
      endPoint: `${Endpoint.Brandings}/${brandingName}/builds/status`,
    });

    return result.data;
  };

  const registerSession = async () => {
    const result: AxiosResponse<any> = await nodeBackAPI.get({
      endPoint: `/register-session`,
    });

    return result.data;
  };

  const getCurrentBrandings = async () => {
    const result: AxiosResponse<any> = await nodeBackAPI.get({
      endPoint: `${Endpoint.USERS}/current/brandings`,
    });

    return result.data;
  };

  const getBuildsHistory = async (branding: string) => {
    const result: AxiosResponse<any> = await nodeBackAPI.get({
      endPoint: `/brandings`,
      params: branding ? { branding } : {},
    });

    return result.data;
  };

  const cancelBrandingBuild = async (branding: string) => {
    const result: AxiosResponse<any> = await nodeBackAPI.post({
      endPoint: `/brandings/${branding}/builds/cancel`,
    });

    return result.data;
  };

  return {
    getBuildStatus,
    registerSession,
    triggerAtpBuild,
    triggerAtpBuildRelease,
    getCurrentBrandings,
    getBuildsHistory,
    cancelBrandingBuild,
    nodeBackAPI,
  };
};
