import { NavLink, Route, Routes } from 'react-router-dom';
import { Panel } from 'react-ui-kit-exante';

import { ROUTES } from 'routes';

import { StyledPanelGroup, StyledNavigation } from './TradingTerminals.styled';
import { All, DesktopBrandingTable, MobileBrandingTable } from './components';

export const TradingTerminalsPage = () => {
  return (
    <section>
      <Panel disableBodyPaddings title="Trading Terminals" />

      <StyledPanelGroup>
        <StyledNavigation disableBodyPaddings>
          <NavLink end to={ROUTES.TRADING_TERMINALS}>
            All
          </NavLink>
          <NavLink end to={`${ROUTES.TRADING_TERMINALS}/desktop-platform`}>
            Desktop Platform
          </NavLink>
          <NavLink end to={`${ROUTES.TRADING_TERMINALS}/mobile-ios`}>
            Mobile Pro iOS
          </NavLink>
          <NavLink end to={`${ROUTES.TRADING_TERMINALS}/mobile-android`}>
            Mobile Pro Android
          </NavLink>
        </StyledNavigation>

        <Routes>
          <Route path="desktop-platform" element={<DesktopBrandingTable />} />
          <Route
            path="mobile-ios"
            element={
              <MobileBrandingTable
                key="branding-service-ios-trading-terminal"
                platform="ios"
              />
            }
          />
          <Route
            path="mobile-android"
            element={
              <MobileBrandingTable
                key="branding-service-android-trading-terminal"
                platform="android"
              />
            }
          />

          <Route path="/" element={<All />} />
        </Routes>
      </StyledPanelGroup>
    </section>
  );
};
