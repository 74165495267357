import { FC, ReactElement } from 'react';

import { Loader } from '../../Loader/Loader';

import { ActionButtonStyled } from './ActionButton.styled';
import { ActionButtonProps } from './ActionButton.types';

export const ActionButton: FC<ActionButtonProps> = ({
  loading = false,
  active = false,
  iconColor,
  children,
  component,
  ...rest
}): ReactElement<ActionButtonProps> => {
  if (component) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{component}</>;
  }

  return (
    <ActionButtonStyled
      data-test-id="action-button"
      active={active}
      iconColor={iconColor}
      {...rest}
    >
      {loading ? <Loader size="l" /> : children}
    </ActionButtonStyled>
  );
};
