import { DialogTitle } from '@mui/material';
import { FC } from 'react';

import { DefaultThemeProvider } from '../../theme';
import { Button } from '../Button/Button';
import { FullscreenOverlay } from '../FullscreenOverlay';
import { Loader } from '../Loader';

import { StyledDialogActions, StyledDialogContent } from './Modal.styled';
import { ModalProps } from './Modal.types';

export const Modal: FC<ModalProps> = ({
  isOpened = false,
  title,
  onClose,
  confirmButton,
  cancelButton,
  isLoading,
  disableContentPaddings,
  fullWidth = false,
  maxWidth,
  children,
  disableEscapeKeyDown = false,
  keepMounted = true,
}) => {
  const disabled = confirmButton?.confirmButtonIsDisabled || isLoading;

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <DefaultThemeProvider>
      <FullscreenOverlay
        open={isOpened}
        onClose={onCloseHandler}
        disableEscapeKeyDown={disableEscapeKeyDown}
        keepMounted={keepMounted}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle className="modal__header">{title}</DialogTitle>
        <StyledDialogContent
          disableContentPaddings={disableContentPaddings}
          className="modal__body"
        >
          {isLoading ? <Loader isCentered isInner /> : children}
        </StyledDialogContent>
        <StyledDialogActions className="modal__footer">
          {!cancelButton?.hidden && (
            <Button onClick={onCloseHandler} color="secondary" type="button">
              {cancelButton?.cancelButtonName || 'Close'}
            </Button>
          )}
          {!confirmButton?.hidden && confirmButton?.handleConfirm && (
            <Button
              onClick={confirmButton.handleConfirm}
              disabled={disabled}
              type="button"
            >
              {confirmButton?.confirmButtonName || 'Confirm'}
            </Button>
          )}
        </StyledDialogActions>
      </FullscreenOverlay>
    </DefaultThemeProvider>
  );
};
