import { CircleClockIcon, IRow, WarningCircleIcon } from 'react-ui-kit-exante';

import { BrandingBuildIosResponse } from 'types/brandingBuilds';

import {
  StyledLink,
  StyledStatusFailed,
  StyledStatusProgress,
} from '../../../TradingTerminals.styled';
import { getFileExtension } from '../../../helpers';

interface CellBuildStatusProps {
  row: IRow<BrandingBuildIosResponse>;
}

export const CellBuildStatusIos = ({
  row: {
    original: { error, status, buildArtifact },
  },
}: CellBuildStatusProps) => {
  if (status === 'error') {
    return (
      <StyledStatusFailed>
        <WarningCircleIcon /> <span>{error}</span>
      </StyledStatusFailed>
    );
  }

  if (status === 'created') {
    return (
      <StyledStatusProgress>
        <CircleClockIcon /> <span>In progress</span>
      </StyledStatusProgress>
    );
  }

  if (status === 'done' && buildArtifact?.binary) {
    return (
      <StyledLink href={buildArtifact.binary} target="_blank" rel="noreferrer">
        {getFileExtension(buildArtifact.binary)}
      </StyledLink>
    );
  }

  return '';
};
