import { useEffect, useRef } from 'react';

type UseRepeatedActionParams = {
  action: () => void; // Function to be executed periodically
  interval?: number; // Interval between each execution (in milliseconds)
  notifyBefore?: number; // Time before execution to trigger the notification (in milliseconds)
  onNotify?: () => void; // Function to be called at notifyBefore milliseconds before the action
};

/**
 * Custom hook that triggers an action repeatedly with a specified interval,
 * with an optional notification beforehand.
 *
 * @param {UseRepeatedActionParams} params - The parameters for the repeated action.
 * @param {() => void} params.action - The function to execute periodically.
 * @param {number} params.interval - The interval between each execution (in milliseconds).
 * @param {number} [params.notifyBefore] - (Optional) The time before each execution to notify (in milliseconds).
 * @param {() => void} [params.onNotify] - (Optional) The function to call for notification.
 *
 * @returns {{
 *   start: () => void,
 *   cancel: () => void
 * }} - An object containing start and cancel functions.
 */
export function useRepeatedAction({
  action,
  interval = 1000 * 60,
  notifyBefore = 1000 * 10,
  onNotify,
}: UseRepeatedActionParams) {
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const notifyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isRunningRef = useRef(false);

  const start = () => {
    if (isRunningRef.current) {
      return;
    } // Prevent multiple starts

    isRunningRef.current = true;

    if (onNotify) {
      notifyTimeoutRef.current = setTimeout(() => {
        onNotify();
      }, interval - notifyBefore);
    }

    const executeAction = () => {
      if (onNotify) {
        notifyTimeoutRef.current = setTimeout(() => {
          onNotify();
        }, interval - notifyBefore);
      }

      action();
    };

    intervalIdRef.current = setInterval(executeAction, interval);
  };

  const cancel = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    if (notifyTimeoutRef.current) {
      clearTimeout(notifyTimeoutRef.current);
    }
    isRunningRef.current = false;
  };

  useEffect(() => {
    return () => {
      cancel(); // Cleanup on component unmount
    };
  }, []);

  return { startRepeatedAction: start, cancelRepeatedAction: cancel };
}
