import { IRow } from 'react-ui-kit-exante';

import {
  AndroidArtifact,
  BrandingBuildAllPlatformsResponse,
  IosArtifact,
} from '../../../../../types/brandingBuilds';
import {
  StyledLink,
  StyledLinkWrapper,
} from '../../../TradingTerminals.styled';
import { getFileExtension } from '../../../helpers';

interface CellBuildLinkProps {
  row: IRow<BrandingBuildAllPlatformsResponse>;
}

export const CellBuildLink = ({
  row: {
    original: { buildArtifact, platform },
  },
}: CellBuildLinkProps) => {
  if (platform === 'ios' && buildArtifact) {
    return (
      <StyledLink
        href={(buildArtifact as IosArtifact).binary}
        target="_blank"
        rel="noreferrer"
      >
        {getFileExtension((buildArtifact as IosArtifact).binary)}
      </StyledLink>
    );
  }

  if (platform === 'android' && buildArtifact) {
    return (
      <StyledLink
        href={(buildArtifact as AndroidArtifact).apk}
        target="_blank"
        rel="noreferrer"
      >
        {getFileExtension((buildArtifact as AndroidArtifact).apk)}
      </StyledLink>
    );
  }

  if (platform === 'desktop' && buildArtifact) {
    return (
      <div>
        {'windows' in buildArtifact ? (
          <StyledLinkWrapper>
            <StyledLink
              href={buildArtifact.windows}
              target="_blank"
              rel="noreferrer"
            >
              Windows
            </StyledLink>
          </StyledLinkWrapper>
        ) : null}

        {'linux' in buildArtifact ? (
          <StyledLinkWrapper>
            <StyledLink
              href={buildArtifact.linux}
              target="_blank"
              rel="noreferrer"
            >
              Linux
            </StyledLink>
          </StyledLinkWrapper>
        ) : null}

        {'macos' in buildArtifact ? (
          <StyledLinkWrapper>
            <StyledLink
              href={buildArtifact.macos}
              target="_blank"
              rel="noreferrer"
            >
              macOS
            </StyledLink>
          </StyledLinkWrapper>
        ) : null}
      </div>
    );
  }

  return '-';
};
