import { IRow } from 'react-ui-kit-exante';

import { BrandingBuildAllPlatformsResponse } from 'types/brandingBuilds';

interface CellVersionProps {
  value: string;
  row: IRow<BrandingBuildAllPlatformsResponse>;
}

export const CellVersion = ({
  value,
  row: {
    original: { status, platform },
  },
}: CellVersionProps) => {
  if (platform === 'desktop' && status === 'released') {
    return value;
  }

  if (platform === 'desktop') {
    return '-';
  }

  return value;
};
