import { BrandingBuildPlatform } from 'types/brandingBuilds';

import { TriggerBuildParams } from './types';

export const FETCH_BRANDING_BUILDS_ALL_PLATFORM = '/branding-builds';

export const getFetchBrandingBuildsEndpoint = (
  brandingBuildType: BrandingBuildPlatform,
) => `/branding-builds/${brandingBuildType}`;

export const getTriggerBuildEndpoint = ({
  branding,
  platform,
}: TriggerBuildParams) => {
  const baseEndpoint = `/branding-builds/${platform}/${branding}/trigger-build`;

  if (platform === 'desktop') {
    return baseEndpoint;
  }

  return `${baseEndpoint}?type=pro`;
};

export const getReleaseBuildEndpoint = ({
  branding,
  platform,
}: TriggerBuildParams) => {
  const baseEndpoint = `/branding-builds/${platform}/${branding}/trigger-release`;

  if (platform === 'desktop') {
    return baseEndpoint;
  }

  return `${baseEndpoint}?type=pro`;
};

export const getBuildStatusEndpoint = ({
  branding,
  platform,
}: TriggerBuildParams) => {
  const baseEndpoint = `/branding-builds/${platform}/${branding}/status`;

  if (platform === 'desktop') {
    return baseEndpoint;
  }

  return `${baseEndpoint}?type=pro`;
};

export const getCancelBuildEndpoint = ({
  branding,
  platform,
}: TriggerBuildParams) => {
  const baseEndpoint = `/branding-builds/${platform}/${branding}/cancel`;

  if (platform === 'desktop') {
    return baseEndpoint;
  }

  return `${baseEndpoint}?type=pro`;
};
