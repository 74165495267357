import { isFunction, omit } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { LocalStorage } from '../../../../helpers';
import { IUseDataArguments, useData } from '../../../../hooks';
import {
  DEFAULT_PAGE_SIZE,
  TABLE_FIRST_PAGE,
} from '../../TablePagination/const';
import type { TableViewParamsSchema } from '../../types';
import type {
  IDataProps,
  IFiltersProps,
  IPaginationProps,
  ISortingProps,
} from '../types';
import { useFilters } from '../useFilters';
import { useNegative } from '../useNegative';
import { usePagination } from '../usePagination';
import { useQueryParams } from '../useQueryParams';
import { useSorting } from '../useSorting';

interface IUseTableDataProps<Data> {
  data: IDataProps<Data>;
  excludedParams?: string[];
  filters?: IFiltersProps;
  hasNegativeFilters?: boolean;
  pagination?: IPaginationProps;
  saveViewParamsAfterLeave?: boolean;
  sorting?: ISortingProps;
  tableId?: string;
}

/**
 * You should pass MEMOIZED props
 * like this:
 * useTableData<ITradeState>({
 *   data: dataArgs,
 *   filters: filtersArgs,
 *   sorting: sortingArgs,
 * })
 * dataArgs, filtersArgs, sortingArgs are memoized objects
 */

export function useTableData<Data>({
  data: { onFetch: onFetchData, ...restDataProps },
  excludedParams,
  filters: filtersProps,
  hasNegativeFilters = false,
  pagination: paginationProps,
  saveViewParamsAfterLeave = false,
  sorting: sortingProps,
  tableId,
}: IUseTableDataProps<Data | null>) {
  const shouldSaveViewParams = useMemo(
    () => Boolean(saveViewParamsAfterLeave && tableId),
    [saveViewParamsAfterLeave, tableId],
  );

  const storage = useMemo(() => {
    if (saveViewParamsAfterLeave && !tableId) {
      console.warn(
        '[WARN] useTableData: When saveViewParamsAfterLeave is true, tableId is required',
      );
    }
    const storageKey = `${tableId || ''}-view-params`;

    return new LocalStorage<TableViewParamsSchema>(storageKey);
  }, [saveViewParamsAfterLeave, tableId]);

  const getSyncedPagination = () => {
    const defaultPagination = paginationProps?.getDefaultPagination?.() || {
      skip: TABLE_FIRST_PAGE,
      limit: DEFAULT_PAGE_SIZE,
    };

    const savedPageIndex = storage.get('page');
    const savedPageSize = storage.get('pageSize');

    if (savedPageSize) {
      defaultPagination.limit = savedPageSize;
    }

    if (savedPageIndex) {
      defaultPagination.skip = savedPageIndex * defaultPagination.limit;
    }

    return {
      ...(paginationProps || {}),
      getDefaultPagination: () => defaultPagination,
    };
  };

  const getSyncedSorting = () => {
    let defaultSorting = sortingProps?.getDefaultSorting?.() || [
      {
        id: 'id',
        desc: false,
      },
    ];

    const savedSorting = storage.get('sorting');

    if (Array.isArray(savedSorting) && savedSorting.length > 0) {
      defaultSorting = savedSorting;
    }

    return {
      ...(sortingProps || {}),
      getDefaultSorting: () => defaultSorting,
    };
  };

  const {
    filters,
    limit,
    negative,
    page,
    params,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    setSorting,
    skip,
    sorting,
  } = useQueryParams({
    filters: {
      ...filtersProps,
      ...(shouldSaveViewParams
        ? { savedFilters: storage.get('filters') ?? {} }
        : {}),
    },
    excludedParams,
    hasNegativeFilters,
    pagination: shouldSaveViewParams ? getSyncedPagination() : paginationProps,
    sorting: shouldSaveViewParams ? getSyncedSorting() : sortingProps,
    useFilters,
    useNegative,
    usePagination,
    useSorting,
  });

  const resetPagination = () => setPage(TABLE_FIRST_PAGE);

  const preparedFetchData = useCallback((): ReturnType<
    IUseDataArguments<Data | null>['onFetch']
  > => {
    return onFetchData({
      params: { ...params, negative },
      filtersParams: {
        ...filters,
        negative,
      },
      paginationParams: {
        limit,
        skip,
        page,
      },
      sortingParams: sorting,
    });
  }, [onFetchData, filters, limit, skip, sorting, negative]);

  const { data, fetchData, isLoading, error } = useData<Data>({
    onFetch: preparedFetchData,
    ...restDataProps,
    onlyLastRequest: true,
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const wrappedSetSorting = useCallback(
    (newSorting: { desc?: boolean | undefined; id: string }[]) => {
      if (shouldSaveViewParams) {
        storage.set('sorting', newSorting);
      }

      setSorting(newSorting);
    },
    [setSorting, storage, shouldSaveViewParams],
  );

  const syncedSorting = useMemo(() => {
    if (shouldSaveViewParams) {
      const savedSorting = storage.get('sorting');

      return savedSorting || sorting;
    }
    return sorting;
  }, [shouldSaveViewParams, sorting, storage]);

  const syncWithStorage = useCallback(
    <Key extends keyof TableViewParamsSchema>(
      key: Key,
      value: TableViewParamsSchema[Key],
    ) => {
      if (shouldSaveViewParams) {
        storage.set(key, value);
      }
    },
    [shouldSaveViewParams, storage],
  );

  const handleSetPage: typeof setPage = useCallback(
    (pageIndex) => {
      syncWithStorage(
        'page',
        isFunction(pageIndex) ? pageIndex(page) : pageIndex,
      );

      setPage(pageIndex);
    },
    [page, setPage, syncWithStorage],
  );

  const handleSetLimit: typeof setLimit = useCallback(
    (pageSize) => {
      syncWithStorage(
        'pageSize',
        isFunction(pageSize) ? pageSize(limit) : pageSize,
      );

      resetPagination();
      setLimit(pageSize);
    },
    [limit, setLimit, syncWithStorage],
  );

  const handleSetFilter = useCallback(
    (column: string, value: unknown) => {
      if (shouldSaveViewParams) {
        const savedFilters = storage.get('filters');

        const newViewParams = {
          ...(savedFilters || {}),
        };

        newViewParams[column] = value;

        storage.set('filters', newViewParams);
      }

      resetPagination();
      setFilter(column, value);
    },
    [setFilter, shouldSaveViewParams, storage],
  );

  const handleSetNegativeFilter = useCallback(() => {
    if (shouldSaveViewParams) {
      const savedFilters = storage.get('filters') || {};
      let viewParamsWithNegative: Record<string, unknown>;

      if (savedFilters) {
        viewParamsWithNegative = { ...savedFilters, negative };

        storage.set('filters', viewParamsWithNegative);
      }
    }
  }, [shouldSaveViewParams, storage, negative]);

  useEffect(() => {
    handleSetNegativeFilter();
  }, [negative]);

  const handleRemoveFilter = useCallback(
    (column: string) => {
      if (filtersProps?.required?.includes(column)) {
        return;
      }
      if (shouldSaveViewParams) {
        const savedFilters = storage.get('filters') || {};
        let newViewParams: Record<string, unknown>;

        if (savedFilters) {
          newViewParams = omit(savedFilters, column);

          storage.set('filters', newViewParams);
        }
      }

      resetPagination();
      removeFilter(column);
    },
    [removeFilter, shouldSaveViewParams, storage],
  );

  const handleResetFilters = useCallback(
    (filtersList: string[]) => {
      if (
        filtersProps &&
        filtersProps.required &&
        filtersProps.required.length > 0
      ) {
        filtersList.forEach((filterName: string) => {
          if (!filtersProps.required?.includes(filterName)) {
            handleRemoveFilter(filterName);
          }
        });
        return;
      }

      if (shouldSaveViewParams) {
        storage.remove('filters');
      }

      resetPagination();
      resetFilters(filtersList);
    },
    [resetFilters, shouldSaveViewParams, storage],
  );

  return {
    data,
    error,
    fetchData,
    filters,
    isLoading,
    limit,
    negative,
    page,
    params,
    removeFilter: handleRemoveFilter,
    resetFilters: handleResetFilters,
    setFilter: handleSetFilter,
    setLimit: handleSetLimit,
    setPage: handleSetPage,
    setSorting: wrappedSetSorting,
    skip,
    sorting: syncedSorting,
  };
}
