interface CellPlatformProps {
  value: string;
}

export const CellPlatform = ({ value }: CellPlatformProps) => {
  switch (value) {
    case 'desktop':
      return 'Desktop Platform';
    case 'ios':
      return 'Mobile Pro iOS';
    case 'android':
      return 'Mobile Pro Android';
    default:
      return value;
  }
};
