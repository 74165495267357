import { BrandingBuildPlatform } from 'types/brandingBuilds';

import { CellBuildStatusAndroid, CellBuildStatusIos } from './components';

export function getColumns(type: BrandingBuildPlatform) {
  const Cell = {
    desktop: null,
    android: CellBuildStatusAndroid,
    ios: CellBuildStatusIos,
  };

  return [
    {
      Header: 'Branding',
      accessor: 'branding',
      disableSortBy: true,
    },
    {
      Cell: Cell[type],
      Header: 'Build status',
      disableSortBy: true,
    },
    {
      Header: 'Version',
      accessor: 'appVersion',
      disableSortBy: true,
    },
  ];
}
